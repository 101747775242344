import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { InfoCard, Layout, Title } from './Styles';

const DeleteAccount = () => (
  <Layout>
    <Logo
      style={{
        maxWidth: '400px',
        maxHeight: '160px',
        marginBottom: '50px'
      }}
    />

    <InfoCard>
      <Title>Delete account</Title>
      If you’ve decided to delete your account, here’s a step-by-step guide to
      make the process smooth and straightforward:
      <ul>
        <li>
          <div>
            <b>1. Log In to Your Account</b>
          </div>
          <div>
            Begin by accessing the platform. Use your credentials (username and
            password) to log in. If you’re having trouble remembering your
            details, try the “Forgot Password” option to regain access.
          </div>
        </li>
        <li>
          <div>
            <b>2. Locate the Profile Tab</b>
          </div>
          <div>
            Once logged in, in the bottom-right corner there is the profile tab
            option. This is the gateway to your account information.
          </div>
        </li>
        <li>
          <div>
            <b>3. Click Settings Icon</b>
          </div>
          <div>
            In the top-left corner there is the settings icon. Click on it to
            open a screen with settings related to the app.
          </div>
        </li>
        <li>
          <div>
            <b>4. Find the Delete Account Option</b>
          </div>
          <div>
            Within the dropdown menu, look for the option labeled “Delete
            account”
          </div>
        </li>
        <li>
          <div>
            <b>5. Follow the Confirmation Process</b>
          </div>
          <div>
            Once you click “Delete account” the platform may guide you through a
            confirmation process. This is often done to ensure that the action
            is intentional, as deleting your account usually cannot be undone.
            Read the instructions carefully and confirm your choice.
          </div>
        </li>
        <li>
          <div>
            <b>6. Finalize Account Deletion</b>
          </div>
          <div>
            After confirming, your account will be permanently deleted. Make
            sure you’ve backed up any important data or content linked to your
            account, as this process often results in losing all associated
            information.
          </div>
        </li>
      </ul>
    </InfoCard>
  </Layout>
);

export default DeleteAccount;
