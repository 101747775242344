import {
  BaseButton,
  HStack,
  InputWrapper,
  RichTextInput,
  Space,
  TextAreaInput
} from '@codepoint-pt/xela';
import { FieldInputProps, FieldRenderProps } from 'react-final-form';
import { AnyObject } from '../../models/Generic';
import { useContext } from 'react';
import { LanguageContext } from '../../Context';
import { useTranslation, TFunction } from 'react-i18next';
import { Language } from '../../models/Language';
import styled from 'styled-components';
import { useFetch } from 'use-http';

const LabelContainer = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: start;
`;

const LabelIcon = styled.img`
  width: 20px;
  margin-right: 5px;
`;

const Label: React.FC<{
  lang: Language;
  t: TFunction<'translation', undefined>;
}> = ({ lang, t }) => (
  <LabelContainer>
    <LabelIcon src={lang.icon} /> {t(lang.tag)}
  </LabelContainer>
);

interface ITextAreaInput extends Omit<FieldRenderProps<AnyObject>, 'input'> {
  afterChange?: (value: AnyObject) => void;
  input: FieldInputProps<AnyObject>;
  label?: string;
  placeholder?: string;
  richText?: boolean;
  maxChar?: number;
  autoTranslate?: boolean;
}

const IntlTextAreaInput: React.FC<ITextAreaInput> = ({
  input,
  meta,
  label,
  placeholder,
  afterChange,
  richText,
  maxChar,
  autoTranslate = true
}) => {
  const languages = useContext(LanguageContext);
  const { t } = useTranslation();
  const { post, loading } = useFetch('/translations/translate-intl');

  const translate = async () => {
    const { data, success } = await post({ value: input.value });
    if (success) input.onChange(data);
  };

  const value = languages.reduce((result: AnyObject, lang) => {
    if (input.value && input.value[lang.code]) {
      result[lang.code] = input.value[lang.code];
    } else result[lang.code] = '';
    return result;
  }, {});

  const showError = meta?.invalid && meta?.submitFailed;

  const handleInputChange = (
    ev: React.ChangeEvent<HTMLTextAreaElement> | string | null,
    code: string
  ) => {
    const text = typeof ev === 'string' ? ev : ev?.currentTarget?.value;
    const newValue = { ...value };
    newValue[code] = text;
    input.onChange(newValue);
    if (afterChange) afterChange(newValue);
  };

  return (
    <InputWrapper label={label}>
      <Space h={5} />
      {languages.map((lang) => {
        const error =
          showError &&
          meta.error &&
          (typeof meta.error === 'string' || !!meta.error[lang.code]);

        if (richText)
          return (
            <RichTextInput
              label={<Label t={t} lang={lang} />}
              key={lang.code}
              input={{
                value: value[lang.code],
                onChange: (html: string | null) =>
                  handleInputChange(html, lang.code)
              }}
              placeholder={t(placeholder || lang.tag, { lang: t(lang.tag) })}
              meta={{ ...meta, invalid: error }}
            />
          );

        return (
          <TextAreaInput
            key={lang.code}
            label={<Label t={t} lang={lang} />}
            input={{
              value: value[lang.code],
              onChange: (html: string | null) =>
                handleInputChange(html, lang.code)
            }}
            placeholder={t(placeholder || lang.tag, { lang: t(lang.tag) })}
            meta={{ ...meta, invalid: error }}
            autosize
            minRows={3}
            maxChar={maxChar}
          />
        );
      })}
      <Space h={10} />
      {autoTranslate && (
        <HStack justifyContent="center">
          <BaseButton
            size="xs"
            variant="subtle"
            action="default"
            onClick={translate}
            loading={loading}
          >
            {t('AUTOMATIC_TRANSLATION')}
          </BaseButton>
        </HStack>
      )}
    </InputWrapper>
  );
};

export default IntlTextAreaInput;
