import { Tabs } from '@codepoint-pt/xela';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { LanguageContext } from '../../Context';

const LanguageTabs: React.FC<{
  children: (lang: string) => React.ReactElement | React.ReactElement[];
}> = ({ children }) => {
  const { t } = useTranslation();
  const languages = useContext(LanguageContext);

  return (
    <Tabs radius="md" defaultValue="pt" keepMounted={false}>
      <Tabs.List>
        {languages.map((lang) => (
          <Tabs.Tab
            key={lang.code}
            icon={<img style={{ width: '18px' }} src={lang.icon} />}
            value={lang.code}
          >
            {t(lang.tag)}
          </Tabs.Tab>
        ))}
      </Tabs.List>

      {languages.map((lang) => (
        <Tabs.Panel key={lang.code} value={lang.code}>
          {children(lang.code)}
        </Tabs.Panel>
      ))}
    </Tabs>
  );
};

export default LanguageTabs;
