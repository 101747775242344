import { FC, ReactElement, useContext } from 'react';

import { Navigate } from 'react-router-dom';
import { UserContext } from '../../Context';

const UnauthenticatedRoute: FC<{
  forceValidation?: boolean;
  children: ReactElement;
}> = ({ children, forceValidation }) => {
  const { user } = useContext(UserContext);
  if (user && forceValidation) {
    return <Navigate replace to="/" />;
  }
  return children;
};

export default UnauthenticatedRoute;
