import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { StyleProvider as XelaProvider, XelaColor } from '@codepoint-pt/xela';
import Theme from './Theme';

const BasicStyles = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }

  body {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: 'Nunito Sans', sans-serif;
    color: ${XelaColor.Gray3};
  }

  * {
    box-sizing: border-box;
  }
`;

const StyledProvider: React.FC<{ children: React.ReactElement }> = ({
  children
}) => {
  return (
    <XelaProvider>
      <DndProvider backend={HTML5Backend}>
        <ThemeProvider theme={Theme}>
          <BasicStyles />
          {children}
        </ThemeProvider>
      </DndProvider>
    </XelaProvider>
  );
};

export default StyledProvider;
