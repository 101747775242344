import { BaseButton, Dropzone, HStack } from '@codepoint-pt/xela';
import { FieldInputProps, FieldRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { showError } from '../../hooks/show-notification/show-notification';
import { GeoJson } from '../../models/Maps';

const readFileAsText = (file: File) =>
  new Promise<GeoJson>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        if (reader.result && typeof reader.result === 'string') {
          resolve(JSON.parse(reader.result || ''));
        } else reject();
      },
      false
    );
    reader.readAsText(file);
  });

interface UploadGeojsonProps extends Omit<FieldRenderProps<File>, 'input'> {
  input: FieldInputProps<File>;
  refocusMap: (geojson: GeoJson) => void;
}

const UploadGeojson = ({ input, refocusMap }: UploadGeojsonProps) => {
  const { t } = useTranslation();

  const handleAccept = async (files: File[]) => {
    try {
      if (files[0]) {
        input.onChange(files[0]);
        const geojson = await readFileAsText(files[0]);
        refocusMap(geojson);
      }
    } catch (e) {
      showError({
        title: t('FILE_ERROR'),
        message: t('UNABLE_TO_READ_GEOJSON')
      });
    }
  };

  const handleReject = () => {
    showError({
      title: t('FILE_ERROR'),
      message: t('UNABLE_TO_READ_GEOJSON')
    });
  };

  return (
    <HStack justifyContent="flex-end">
      <Dropzone
        onDrop={handleAccept}
        onReject={handleReject}
        accept={['application/geo+json']}
        multiple={false}
        styles={{
          root: {
            border: 'none',
            padding: 0
          }
        }}
      >
        <BaseButton variant="light" action="secondary">
          {t('UPDATE_GEOJSON')}
        </BaseButton>
      </Dropzone>
    </HStack>
  );
};

export default UploadGeojson;
