import {
  InputWrapper,
  XelaColor,
  CreatePreviewFile,
  Icon
} from '@codepoint-pt/xela';
import { FieldInputProps, FieldRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { showError } from '../../hooks/show-notification/show-notification';
import { Dropzone } from '@mantine/dropzone';
import { useRef, useState } from 'react';
import { AnyObject } from '../../models/Generic';

const InputSection = styled.div<{ hasError?: boolean }>`
  margin-top: 5px;
  width: 100%;
  height: 36px;
  line-height: 34px;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  color: #000;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-self: center;
  text-align: left;
  min-height: 36px;
  padding-left: 0;
  padding-right: 0;
  border-radius: 16px;
  border: 1px solid
    ${({ hasError }) => (hasError ? XelaColor.Red5 : XelaColor.Gray10)};
  -webkit-transition: border-color 100ms ease;
  transition: border-color 100ms ease;
  position: relative;
`;

const IconContainer = styled.div`
  display: inline-block;
  width: 50px;
`;

const PlaceholderContainer = styled.div<{ padding?: boolean }>`
  display: inline-block;
  padding: 0 10px;
  padding-left: ${({ padding }) => (padding ? 40 : 10)}px;
`;

const PlayButton = styled.div`
  position: absolute;
  cursor: pointer;
  bottom: 7px;
  left: 60px;
`;

export interface AudioInputProps
  extends Omit<FieldRenderProps<AnyObject>, 'input'> {
  afterChange?: (value: AnyObject) => void;
  input: FieldInputProps<AnyObject>;
  label?: string;
  apiFileUrl?: string;
  placeholder?: string;
  errorTitle?: string;
  errorMessage?: string;
  icon?: React.ReactNode;
}

export const AudioInput: React.FC<AudioInputProps> = ({
  input,
  meta,
  label,
  apiFileUrl = process.env.REACT_APP_FILE_URL,
  placeholder = 'Drag or drop MP3 File',
  errorTitle = 'Invalid MP3',
  errorMessage = 'The file you tried to upload is invalid',
  icon
}) => {
  const [playing, setPlaying] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const hasError = meta?.invalid && meta?.submitFailed;

  const handleAccept = (files: File[]) => {
    if (files[0]) {
      input.onChange(CreatePreviewFile(files[0]));
    }
  };

  const handleReject = () => {
    showError({
      title: errorTitle,
      message: errorMessage,
      color: 'red'
    });
  };

  const playAudio = () => {
    const url = input.value?.preview || input.value?.url;
    audioRef.current = new Audio(url);
    audioRef.current.play();
    setPlaying(true);
  };

  const pauseAudio = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    setPlaying(false);
  };

  const renderDropzone = input?.value ? (
    <PlaceholderContainer padding>
      {input.value?.blob?.name || input.value?.name}
    </PlaceholderContainer>
  ) : (
    <PlaceholderContainer>{placeholder}</PlaceholderContainer>
  );

  const renderPlayer =
    input?.value &&
    (playing ? (
      <PlayButton onClick={pauseAudio}>
        <Icon icon="basics_pause" size={20} />
      </PlayButton>
    ) : (
      <PlayButton onClick={playAudio}>
        <Icon icon="basics_play" size={20} />
      </PlayButton>
    ));

  return (
    <InputWrapper label={label}>
      <InputSection hasError={hasError}>
        <IconContainer>{icon}</IconContainer>
        <Dropzone
          onDrop={handleAccept}
          onReject={handleReject}
          accept={['audio/mpeg']}
          multiple={false}
          styles={{
            root: {
              border: 'none',
              padding: 0,
              display: 'inline-block',
              width: 'calc(100% - 50px)',
              borderBottomLeftRadius: 0,
              borderTopLeftRadius: 0
            }
          }}
        >
          {renderDropzone}
        </Dropzone>
        {renderPlayer}
      </InputSection>
    </InputWrapper>
  );
};

export default AudioInput;
