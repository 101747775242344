import {
  Icon,
  IconTypes,
  Space,
  Typography,
  XelaColor
} from '@codepoint-pt/xela';
import { useTranslation } from 'react-i18next';
// import styled from 'styled-components';

// const EmptySection = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: center;
//   align-items: center;
//   min-height: 260px;
//   flex-direction: column;
// `;

const EmptyState: React.FC<{
  icon?: IconTypes;
  title?: string;
  tag?: string;
}> = ({
  icon = 'tasks_clipboard',
  title = 'TABLE_EMPTY_TITLE',
  tag = 'TABLE_EMPTY_MESSAGE'
}) => {
  const { t } = useTranslation();
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '260px'
      }}
    >
      <Icon icon={icon} size={50} color={XelaColor.Gray8} />
      <Space h={15} />
      <Typography variant="subheadline" color={XelaColor.Gray8}>
        {t(title)}
      </Typography>
      <Typography variant="body-small" color={XelaColor.Gray8}>
        {t(tag)}
      </Typography>
    </div>
  );
};

export default EmptyState;
