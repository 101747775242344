import { Burger } from '@codepoint-pt/xela';
import { HeaderWrapper, Logo } from './HeaderStyles';

interface HeaderProps {
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  logo: {
    image: string;
    maxWidth?: string;
    maxHeight?: string;
  };
}

export const Header = ({ logo, menuOpen, setMenuOpen }: HeaderProps) => {
  return (
    <HeaderWrapper>
      <Burger
        size="md"
        opened={menuOpen}
        onClick={() => setMenuOpen(!menuOpen)}
      />
      <Logo src={logo.image} alt="logo" />
    </HeaderWrapper>
  );
};

export default Header;
