import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import Layout from '../../components/layout/Layout';
import { UserContext } from '../../Context';

const AuthenticatedRoute: React.FC<{
  privileged?: boolean;
  children: React.ReactElement;
}> = ({ children, privileged }) => {
  const { user } = useContext(UserContext);
  if (!user) {
    return <Navigate replace to="/login" />;
  }
  if (privileged && !user?.superuser) {
    return <Navigate replace to="/" />;
  }
  return <Layout>{children}</Layout>;
};

export default AuthenticatedRoute;
